import React, { memo } from "react";
import { appConfig } from "../../../../utils/constants";
import ResultItem from "./ResultItem";
import addToDataLayer from "../../../../utils/tracker";

function ResultsBlock(props) {
  const { status } = props;

  if (status !== "complete") {
    return null;
  }

  const paging = {
    index: appConfig.runtimeConfig.page,
    size: props.clientConfig.searchPerPageCount || appConfig.pagerConfig.perpage
  };

  var cars = props.cars.map(function(car, index) {
    return (
      <ResultItem
        clientConfig={props.clientConfig}
        key={index}
        car={car.node}
        index={(paging.index - 1) * paging.size + index + 1}
      />
    );
  });

  addToDataLayer('impressions', {
    ecommerce: {
      currencyCode: "RUB",
      impressions: props.cars.map((car, index) => ({
        id: "" + car.id,
        position: (paging.index - 1) * paging.size + index + 1,
        list: "Search Results"
      }))
    }
  });

  return <div>{cars}</div>;
}

export default memo(ResultsBlock);
