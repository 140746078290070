import React from "react";
import { rpc } from "../../utils/Global";

class AutoHeight extends React.Component {
  constructor(props) {
    super(props);

    this.root = document.getElementById("root");

    this.lastHeight = 0;
  }

  componentDidMount() {
    this.resize();
  }

  componentDidUpdate(prevProps) {
    this.resize();
  }

  resize() {
    if (this.lastHeight !== this.root.offsetHeight) {
      rpc.resize(this.root.offsetHeight);
      this.lastHeight = this.root.offsetHeight;
    }
  }

  render() {
    return null;
  }
}

export default AutoHeight;
