import React from "react";
import { replaceSubstringByValue } from "../../utils/Global";
import Link from "./Link";

export const ButtonBlock = ({
  additional,
  clientConfig,
  car,
  location = "to-result-cell",
}) => {
  const {
    searchWishLink,
    searchWishLinkAdditional,
    searchWishLinkNewTab,
    searchWishLinkNewTabAdditional,
    searchWishText = "ХОЧУ!",
    searchWishTextAdditional = "ЗАБРОНИРОВАТЬ",
    searchWishModelCase = {},
  } = clientConfig;
  const wishLink = additional ? searchWishLinkAdditional : searchWishLink;
  const target = additional
    ? searchWishLinkNewTabAdditional
    : searchWishLinkNewTab;
  const text = additional ? searchWishTextAdditional : searchWishText;
  const renderHref = () => (
    <a
      href={encodeURI(replaceSubstringByValue(wishLink, car))}
      target={target ? "_new" : "_parent"}
      className={`button-green unselectable ${location}`}
    >
      {text}
    </a>
  );
  const renderLink = () => (
    <Link
      to={`/claim/car/${car.id}`}
      className={`button-green unselectable ${location}`}
    >
      {searchWishModelCase[car.model]
        ? searchWishModelCase[car.model]
        : searchWishText}
    </Link>
  );

  return additional ? (
    wishLink ? (
      <div>{renderHref()}</div>
    ) : null
  ) : (
    <div>{wishLink ? renderHref() : renderLink()}</div>
  );
};
