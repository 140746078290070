import React from "react";
import { withRouter } from "react-router-dom";
import { withConfig } from "../../context/ConfigContext";
import { rpc } from "../../utils/Global";

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    rpc.navigate(null, true, this.props.clientConfig.clientScrollOffset);
  }

  render() {
    return null;
  }
}

const configScrollToTopOnMount = withConfig(ScrollToTopOnMount);

export { configScrollToTopOnMount as ScrollToTopOnMount };

class ScrollCardPageToTopOnMount extends React.Component {
  componentDidMount() {
    if(this.props.location.pathname.includes('/car/')) {
      setTimeout(() => {
        rpc.navigate("#" + this.props.location.pathname + this.props.location.search, true, this.props.clientConfig.clientScrollOffset);
      }, this.props.clientConfig.clientScrollForCardDelayInMs || 1000)
    }
  }

  render() {
    return null;
  }
}

const configScrollCardPageToTopOnMount = withRouter(withConfig(ScrollCardPageToTopOnMount));

export { configScrollCardPageToTopOnMount as ScrollCardPageToTopOnMount };

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const loc = this.props.location,
      prevLoc = prevProps.location;

    if (loc !== prevLoc && this.props.history.action !== "REPLACE") {
      rpc.navigate(
        "#" + this.props.location.pathname + this.props.location.search,
        true,
        this.props.clientConfig.clientScrollOffset || 0
      );
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(withConfig(ScrollToTop));
