import React from "react";
import SearchPageFiltersBlock from "../components/SearchPage/FiltersBlock";
import ConfigContext from "../context/ConfigContext";

function SearchPage(props) {
  return (
    <ConfigContext.Consumer>
      {config => (
        <SearchPageFiltersBlock
          clientConfig={config}
          sessionConfig={props.sessionConfig}
        />
      )}
    </ConfigContext.Consumer>
  );
}

export default SearchPage;
