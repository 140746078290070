import React from "react";
import $ from "jquery";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

const ButtonItem = createReactClass({
  handleClick: function() {
    $("#" + this.props.id).hasClass("selected") &&
    $("#" + this.props.id).removeClass("selected")
      ? this.props.updateFilterItem(false, this.props.parent, "", false)
      : $("#" + this.props.id)
          .addClass("selected")
          .siblings()
          .removeClass("selected") &&
        this.props.updateFilterItem(
          false,
          this.props.parent,
          this.props.value,
          false
        );
    $("#" + appConfig.filterItems[this.props.parent].children)
      .val(0)
      .trigger("change");
  },
  render: function() {
    return (
      <button
        className={
          "filters-block__button-item " +
          (this.props.values === this.props.id ? "selected" : "")
        }
        onClick={this.handleClick}
        id={this.props.id}
      >
        {this.props.clientConfig.searchFilterTranslator &&
        this.props.clientConfig.searchFilterTranslator[this.props.parent] &&
        this.props.clientConfig.searchFilterTranslator[this.props.parent][
            this.props.name
        ]
          ? this.props.clientConfig.searchFilterTranslator[this.props.parent][
                this.props.name
            ]
          : this.props.name}
      </button>
    );
  }
});

export default ButtonItem;
