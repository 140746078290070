import React from "react";
import {canView, clearEmpty, searchTextReplace} from "../utils/Global";
import CardPageTop from "../components/CardPage/CardPageTop";
import CardPageTabs from "../components/CardPage/CardPageTabs";
import {withConfig} from "../context/ConfigContext";
import CloseButton from "../components/shared/CloseButton";
import AccessDenied from "../components/shared/AccessDenied";
import AutoHeight from "../components/shared/AutoHeight";
import {Redirect} from "react-router-dom";
import addToDataLayer from "../utils/tracker";
import graphqlClient from "../graphql/services/client";
import {getCard} from "../graphql/queries";

const createDescription = (description) => {
    if(!description) return [];

    const featuresWithoutAdditionalDesc = description.features.filter(item => item.name !== "Дополнительное описание");
    const result = [...featuresWithoutAdditionalDesc, ...parseFlags(description.flags)];

    const additionalDesc = description.features.find(item => item.name === "Дополнительное описание");
    if(additionalDesc){
        if(!additionalDesc.value) {
            additionalDesc.name = null;
        }
        result.push(additionalDesc);
    }

    return result;
}

const parseFlags = (flagsList) => {
    return flagsList.filter(item => !!item.has).map(item => ({
        base: true,
        isFlag: true,
        name: item.label,
        value: null,
        ins: []
    }))
}

class CardPage extends React.Component {
    mounted = true;
    state = {
        loading: true
    };

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        const {clientConfig} = this.props;
        const vars = {
            slug: this.props.match.params.id,
        }
        graphqlClient.fetch(getCard, vars)
            .then(res => {
                const resCar = res.offer;
                const car = {
                    attrs: {
                        main: createDescription(resCar.edition.description[0]),
                        body: createDescription(resCar.edition.description[1]),
                        interior: createDescription(resCar.edition.description[2]),
                        safety: createDescription(resCar.edition.description[3]),
                        equip: createDescription(resCar.edition.description[4]),
                        warranty: createDescription(resCar.edition.description.find(item => item.name === 'Гарантия')),
                        tech: resCar.edition.specifications,
                    },
                    availability: resCar.availability,
                    brand: clientConfig.searchTextReplace
                        ? searchTextReplace(
                            resCar.edition.make.name,
                            clientConfig.searchTextReplace
                        )
                        : resCar.edition.make.name,
                    model: clientConfig.searchAltModelVisible && resCar.dealerModelName
                        ? resCar.dealerModelName
                        : resCar.edition.model.name,
                    edition: resCar.edition,
                    retailPrice: resCar.prices.sale.amount,
                    basePrice: resCar.prices.base.amount,
                    retailPriceVisible: resCar.prices.sale.visible,
                    basePriceVisible: resCar.prices.base.visible,
                    discounts: resCar.discounts,
                    maxPrice:
                        Math.max(
                            resCar.prices.sale.amount || 0,
                            resCar.prices.base.amount || 0
                        ),
                    minPrice:
                        Math.min(
                            resCar.prices.sale.amount || 0,
                            resCar.prices.base.amount || 0
                        ) ||
                        resCar.prices.sale.amount ||
                        0
                    ,
                    marketingPrice: resCar.prices.marketing.amount || 0,
                    creditPrice:
                        parseInt(
                            ((clientConfig.searchPriceType === 2
                                ? Math.max(
                                    resCar.prices.sale.amount || 0,
                                    resCar.prices.base.amount || 0
                                )
                                : resCar.prices.sale.amount || 0) /
                                100) *
                            clientConfig.searchCreditValue
                        ) || 0,
                    color: resCar.color.name,
                    complectation: resCar.edition.versionName,
                    location: resCar.dealer.region.name,
                    special: resCar.special,
                    phone: resCar.dealer.phoneNumber.replace(/[^\/\d]/g, ""),
                    dealer_id: resCar.dealer.id,
                    interior: this.interiorDescription(resCar),
                    interiorObject: resCar.edition.interior,
                    dealer: resCar.dealer,
                    description: resCar.comment,
                    extra: resCar.additionalEquipment,
                    id: resCar.id,
                    labels: {special: resCar.special},
                    photos: resCar.photoUrls,
                    image: resCar.catalogPhotoUrl,
                    sku: resCar.sku,
                    uptodate: resCar.updateAt,
                    year: resCar.year,
                    active: resCar.active,
                    used: resCar.used,
                    filters: resCar.edition.filters
                }
                this.setState({loading: false, car: car})
            })
            .catch((e) => {
                console.error(e);
                this.setState({loading: false});
            });
    }

    interiorDescription = (resCar) => {
        let interiorDescription = resCar.edition.description.find(
            desc => desc.name === "Салон",
        ).features;
        let interior = interiorDescription.find(
            desc => desc.name === "Обивка сидений",
        );
        let interiorColor = interiorDescription.find(
            desc => desc.name === "Цвет обивки сидений",
        );
        let descriptionsList = [`${resCar.color.name} ${resCar.color.code}`];
        if (interior) {
            descriptionsList.push(
                interior.ins && interior.ins.length > 0
                    ? interior.ins[0].description
                    : `Обивка салона: ${interior.value}`,
            );
        }
        if (interiorColor) {
            descriptionsList.push(
                interiorColor.ins && interiorColor.ins.length > 0
                    ? interiorColor.ins[0].description
                    : `Цвет обивки сидений: ${interiorColor.value}`,
            );
        }

        return clearEmpty(descriptionsList).map(function (item, index) {
            return <span key={index}>{item}</span>;
        });
    };

    render() {
        const {loading, car} = this.state;
        if (loading) {
            return null;
        }

        if (!car) {
            return <Redirect to="/"/>;
        }

        if (!canView(this.props.clientConfig.dealersIdList, car.dealer.expression)) {
            return <AccessDenied/>;
        }

        addToDataLayer('detail', {
            ecommerce: {
                detail: {
                    products: [
                        {
                            id: "" + car.id
                        }
                    ]
                }
            }
        });

        return (
            <div id="search">
                <CloseButton to="/search" />
                <CardPageTop
                    car={car}
                    clientConfig={this.props.clientConfig}
                />
                <CardPageTabs
                    data={car}
                    clientConfig={this.props.clientConfig}
                    sessionConfig={this.props.sessionConfig}
                />
                <CloseButton
                    to="/search"
                    className="to-bottom"
                />
                <AutoHeight/>
            </div>
        );
    }
}

export default withConfig(CardPage);
