import React from "react";
import { digitBeautify } from "../../utils/Global.js";

class DiscountPriceBlock extends React.Component {
  render() {
    const { car, clientConfig } = this.props;

    return (
      <div className="discountPriceBlock">
          <div className="title">
              Скидки от дилера
          </div>
          <div className="pricesContainer">
              {car.retailPrice &&
                  <div className="row">
                      <div className="rowTitle">Цена без скидки</div>
                      <div className="rowPrice">{`${digitBeautify(car.retailPrice)} ₽`}</div>
                  </div>
              }
              {car.discounts && car.discounts.tradein && car.discounts.tradein.amount &&
                  <div className="row">
                      <div className="rowTitle">Скидка за трейд-ин</div>
                      <div className="rowPrice">{`${digitBeautify(car.discounts.tradein.amount)} ₽`}</div>
                  </div>
              }
              {car.discounts && car.discounts.credit && car.discounts.credit.amount &&
                  <div className="row">
                      <div className="rowTitle">Скидка за кредит</div>
                      <div className="rowPrice">{`${digitBeautify(car.discounts.credit.amount)} ₽`}</div>
                  </div>
              }
              {car.discounts && car.discounts.insurance && car.discounts.insurance.amount &&
                  <div className="row">
                      <div className="rowTitle">Скидка за страхование</div>
                      <div className="rowPrice">{`${digitBeautify(car.discounts.insurance.amount)} ₽`}</div>
                  </div>
              }
              {car.discounts && car.discounts.maximum && car.discounts.maximum.amount &&
                  <div className="row">
                      <div className="rowTitle">Максимальная скидка</div>
                      <div className="rowPrice">{`${digitBeautify(car.discounts.maximum.amount)} ₽`}</div>
                  </div>
              }
              {car.marketingPrice &&
                  <div className="row">
                      <div className="rowTitle">Со всеми скидками</div>
                      <div className="rowPrice bold">{`${digitBeautify(car.marketingPrice)} ₽`}</div>
                  </div>
              }
          </div>
      </div>
    );
  }
}

export default DiscountPriceBlock;
