import React from "react";
import { digitBeautify } from "../../utils/Global.js";

class PriceBlockMobile extends React.Component {
  render() {
    const { car, clientConfig } = this.props;
    let discountString = clientConfig.searchRequestText || (clientConfig.searchRequestDescription && car.description ? car.description : "по запросу");
    let discountPrice = null;
    if(clientConfig.searchPriceType) {
        if (clientConfig.searchPriceType === 1) {
            discountPrice = digitBeautify(car.retailPrice);
        }
        if (clientConfig.searchPriceType === 2) {
            discountPrice = digitBeautify(car.maxPrice);
        }
        if (clientConfig.searchPriceType === 3) {
            discountPrice = digitBeautify(car.minPrice);
        }
    } else if(car.retailPriceVisible){
        discountPrice = digitBeautify(car.retailPrice);
    }

    let basePrice = 0
    if(clientConfig.searchPriceType && clientConfig.searchPriceType === 1 && car.basePrice > car.retailPrice > 0){
        basePrice = digitBeautify(car.basePrice);
    }
    if(car.basePriceVisible && car.basePrice > 0 && car.basePrice >= car.retailPrice && (car.basePrice !== car.retailPrice || !car.retailPriceVisible)){
        basePrice = digitBeautify(car.basePrice);
    }
    if(clientConfig.searchMarketingPriceVisible && car.marketingPrice > 0){
        basePrice = digitBeautify(car.marketingPrice);
    }
    return (
        <>
            {clientConfig.pricesWithDiscounts ?
                <>
                    {car.retailPrice &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Цена без скидки:</div>
                            <div className="price">{`${digitBeautify(car.retailPrice)} ₽`}</div>
                        </div>
                    }
                    {car.discounts && car.discounts.tradein && car.discounts.tradein.amount &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Скидка за трейд-ин:</div>
                            <div className="price">{`${digitBeautify(car.discounts.tradein.amount)} ₽`}</div>
                        </div>
                    }
                    {car.discounts && car.discounts.credit && car.discounts.credit.amount &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Скидка за кредит:</div>
                            <div className="price">{`${digitBeautify(car.discounts.credit.amount)} ₽`}</div>
                        </div>
                    }
                    {car.discounts && car.discounts.insurance && car.discounts.insurance.amount &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Скидка за страхование:</div>
                            <div className="price">{`${digitBeautify(car.discounts.insurance.amount)} ₽`}</div>
                        </div>
                    }
                    {car.discounts && car.discounts.maximum && car.discounts.maximum.amount &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Максимальная скидка:</div>
                            <div className="price">{`${digitBeautify(car.discounts.maximum.amount)} ₽`}</div>
                        </div>
                    }
                    {car.marketingPrice &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Со всеми скидками:</div>
                            <div className="price">{`${digitBeautify(car.marketingPrice)} ₽`}</div>
                        </div>
                    }
                </> :
                <>
                    {Boolean(basePrice) &&
                        <div className="detailPage_mobilePriceBlock">
                            <div>Цена без скидки:</div>
                            <div className="price sale"><span>{basePrice}</span>{` ₽`}</div>
                        </div>
                    }
                    <div className="detailPage_mobilePriceBlock">
                        <div>Цена:</div>
                        <div className="price">{discountPrice ? `${discountPrice} ₽` : discountString}</div>
                    </div>
                </>
            }
        </>
    );
  }
}

export default PriceBlockMobile;
