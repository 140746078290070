import React from "react";
import {
  searchTextReplace,
  digitBeautify,
  clearEmpty
} from "../../../../utils/Global";
import {
  systemLocations,
} from "../../../../utils/constants";
import Link from "../../../shared/Link";
import PriceBlock from "../../../shared/PriceBlock";
import {ButtonBlock} from "../../../shared/ButtonBlock";
import NoCarImage from "../../../../images/no-car-image.svg"

class ResultItem extends React.Component {
  interiorDescription = (sections) => {
      const interior = sections.find(s => s.name === "Салон");
      if (!interior) return;
      const features = interior.features;
      if (!(features && features.length > 0)) return;

      let type = features.find(f => f.name === "Обивка сидений");
      let color = features.find(f => f.name === "Цвет обивки сидений");

      return <>
          {type && <span key={0}>{type.ins && type.ins.length > 0 ? type.ins[0].description : `Обивка салона: ${type.value}`}</span>}
          {color && <span key={1}>{color.ins && color.ins.length > 0 ? color.ins[0].description : `Цвет обивки сидений: ${color.value}`}</span>}
      </>
  };
  createOptions = (year, optionsList) => {
      let list = [year, ...optionsList];
      return list.map(item => <span>{item}</span>)
  }

  render() {
    const { clientConfig, index, car: carFromProps } = this.props;
    const transmissionFromProps = carFromProps.edition.specifications.find(spec => spec.name === "КПП (управление режимами движения)");
    const engineCapacityFromProps = carFromProps.edition.specifications.find(spec => spec.i === 6104);
    const powerFromProps = carFromProps.edition.specifications.find(spec => spec.i === 6105);
    const fuelTypeFromProps = carFromProps.edition.specifications.find(spec => spec.i === 6101);
    const car = {
        id: carFromProps.id,
        databaseId: carFromProps.id,
        num: index,
        brand: clientConfig.searchTextReplace
          ? searchTextReplace(
              carFromProps.edition.make.name,
              clientConfig.searchTextReplace
            )
          : carFromProps.edition.make.name,
        model: carFromProps.edition.model.name,
        complectation: carFromProps.edition.versionName,
        color: carFromProps.color.name,
        location: carFromProps.dealer.region.name,
        dealer: carFromProps.dealer.name,
        dealer_id: carFromProps.dealer.id,
        sku: carFromProps.sku,
        phone: carFromProps.dealer.phoneNumber.replace(/[^\/\d]/g, ""),
        transmission: transmissionFromProps ? transmissionFromProps.value : "",
        engine: (
          <span>
            <span>{engineCapacityFromProps ? engineCapacityFromProps.value : ""} см3, </span>
            <span>
              {fuelTypeFromProps ? fuelTypeFromProps.value : ""}
              ,{" "}
            </span>
            <span>{powerFromProps ? powerFromProps.value : ""} л.с.</span>
          </span>
        ),
        availability: carFromProps.availability,
        options: this.createOptions(carFromProps.year, carFromProps.edition.headline),
        interior: this.interiorDescription(carFromProps.edition.description),
        special: carFromProps.special,
        extra: carFromProps.additionalEquipment,
        description:
          clientConfig.searchExtraDescriptionLength &&
          carFromProps.comment
            ? carFromProps.comment.substring(
                0,
                clientConfig.searchExtraDescriptionLength
              ) + "..."
            : carFromProps.comment,
        retailPrice: carFromProps.prices.sale.amount || 0,
        basePrice: carFromProps.prices.base.amount || 0,
        retailPriceVisible: carFromProps.prices.sale.visible,
        basePriceVisible: carFromProps.prices.base.visible,
        maxPrice:
          Math.max(
            carFromProps.prices.sale.amount || 0,
            carFromProps.prices.base.amount || 0
          ),
        minPrice:
          Math.min(
            carFromProps.prices.sale.amount || 0,
            carFromProps.prices.base.amount || 0
          ) ||
            carFromProps.prices.sale.amount ||
            0,
        marketingPrice: carFromProps.prices.marketing.amount || 0,
        creditPrice:
          parseInt(
            ((clientConfig.searchPriceType === 2
              ? Math.max(
                  carFromProps.prices.sale.amount || 0,
                  carFromProps.prices.base.amount || 0
                )
              : carFromProps.prices.sale.amount || 0) /
              100) *
              clientConfig.searchCreditValue
          ) || 0,
        year: carFromProps.year,
      },
      photos = {};

    carFromProps.photoUrls.forEach(item => {
      photos[item.type] = photos[item.type] || [];
      photos[item.type].push(item.url);
    });

    const carDetail = `/car/${car.id}`;

    return (
      <div className="results-container__result-item">
        <div className="result-item__result-cell num">{car.num}</div>
        <div className="result-item__result-cell i1101">
          <div className="result-cell__result-image-block">
            {car.special && (
              clientConfig.searchSaleLabel ? (
                clientConfig.searchSaleLabel !== 0 && (
                  <img
                    alt=""
                    className="result-image-block__custom-search-sale-label"
                    src={
                      systemLocations.staticLocation +
                      clientConfig.searchSaleLabel
                    }
                  />
                )
              ) : (
                <img
                  alt=""
                  className="result-image-block__custom-search-sale-label"
                  src={
                    systemLocations.staticLocation +
                    systemLocations.searchSaleLabel
                  }
                />
              )
            )}
            <Link
              to={carDetail}
              className="result-cell__result-image"
              style={{
                backgroundImage:
                  `url(${(carFromProps.photoUrls.length > 0 || carFromProps.catalogPhotoUrl) ?
                  (clientConfig.cardGalleryType !== 1 &&
                      carFromProps.photoUrls.length > 0
                    ? carFromProps.photoUrls[0]
                    : carFromProps.catalogPhotoUrl
                  ).replace(
                      "170",
                    clientConfig.searchImageSize
                      ? clientConfig.searchImageSize
                      : "170"
                  ).replace(
                      "/auto/offers",
                    clientConfig.searchImageSize
                        ? `/${clientConfig.searchImageSize}/auto/offers`
                        : "/170/auto/offers"
                  ) : NoCarImage})`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
              }}
            >
                {
                    (carFromProps.photoUrls.length === 0 && !carFromProps.catalogPhotoUrl) && <span style={{
                        marginBottom: "10px",
                        opacity: "0.5",
                        fontSize: "9px",
                        color: "#3a3f45",
                        textTransform: "uppercase"
                    }}>
                       Изображение отсутствует
                   </span>
                }
            </Link>
            <div>{car.color}</div>
          </div>
          <div className="result-cell__result-descr">
            <Link to={carDetail}>
              <div className="result-descr__result-title">
                <span className="car-title__brand">{car.brand}</span>{" "}
                <span className="car-title__model">{car.model}</span>
                <div className="result-title__result-equipment">
                  {car.complectation}
                </div>
              </div>
            </Link>
            <div className="result-descr__result-options">
              {car.options}
              {clientConfig.searchOptionsLength ? "..." : false}
            </div>
            <div className="result-descr__result-interior">{car.interior}</div>

            {car.extra && (
              <div
                className="result-descr__result-extra extra"
                dangerouslySetInnerHTML={{ __html: car.extra }}
              />
            )}
            {car.description && (
              <div
                className="result-descr__result-extra description"
                dangerouslySetInnerHTML={{ __html: car.description }}
              />
            )}
            <div className="result-item__result-cell touch">
              <span>{car.transmission}</span>
              <span>, </span>
              <span>{car.engine}</span>
            </div>
            <div className="result-descr__location">
              {clientConfig.searchLocation
                ? car.dealer + ", "
                : false}{" "}
              {car.location}{car.availability === "IN_STOCK" ? ", в наличии" : ", в пути"}
            </div>
          </div>
        </div>
        <div className="result-item__result-cell kpp">{car.transmission}</div>
        <div className="result-item__result-cell engine">{car.engine}</div>
        <div className="result-item__result-cell price">
          <PriceBlock car={car} clientConfig={clientConfig} />
          {clientConfig.searchCreditValue && (
            <div className="result-cell__credit-value">
              <div>
                <span>
                  от {digitBeautify(car.creditPrice)}
                  <span className="rouble to-result-cell" />
                </span>
              </div>
              <i>в месяц</i>
            </div>
          )}

          <ButtonBlock car={car} clientConfig={clientConfig} location="to-result-cell basic"/>
          <ButtonBlock car={car} clientConfig={clientConfig} additional location="to-result-cell additional"/>

          {clientConfig.searchCreditLink && (
            <Link to={`/credit/${car.id}`} className="result-cell__credit-link">
              в кредит
            </Link>
          )}
        </div>
        <a
          className="result-item__callto-button into-result-item"
          target="_parent"
          href={"tel:+7" + car.phone}
        />
      </div>
    );
  }
}

export default ResultItem;
