import React from "react";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

const DateItem = createReactClass({
  render: function() {
    return (
      <div className="filters-block__date-item">
        <label htmlFor={this.props.id}>
          {appConfig.dateItems[this.props.id].title}
        </label>
        <input
          type="text"
          id={this.props.id}
          defaultValue={
            this.props.clientConfig.searchFilterHiddenNew &&
            this.props.clientConfig.searchFilterHiddenNew[this.props.id]
          }
        />
      </div>
    );
  }
});

export default DateItem;
