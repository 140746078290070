import React from "react";
import $ from "../../../vendor/jquery.jstyling.min";
import graphqlClient from "../../../graphql/services/client";
import {
    digitBeautify, getNewFilterName, getOldFilterName, queryToJSON,
    replaceSubstringByValue,
    rpc
} from "../../../utils/Global";
import {
    appConfig,
} from "../../../utils/constants";
import ButtonItem from "./ButtonItem";
import FilterItem from "./FilterItem";
import CheckItem from "./CheckItem";
import InputItem from "./InputItem";
import DateItem from "./DateItem";
import TitlesBlock from "./TitlesBlock";
import ResultsBlock from "./ResultsBlock";
import PagerBlock from "./PagerBlock";
import {withRouter} from "react-router-dom";
import {ScrollToTopOnMount} from "../../shared/ScrollToTop";
import AutoHeight from "../../shared/AutoHeight";
import {
    getCarsList,
    getCarsListWithAllRegions,
    getCarsListWithRegions,
    getCarsListWithSelectedRegions, getFilters,
    getOffersList
} from "../../../graphql/queries";

let isRepeatedUpdateData = false;

class FiltersBlock extends React.Component {
    brandsList = [];
    modelList = [];
    state = {
        filters: {},
        initFilters: null,
        cars: [],
        status: "loading",
        count: 0,
        extraSearch: 0,
        loading: true,
        values: this.props.clientConfig.searchFilterStartup ||
            queryToJSON(document.location.hash.split("?")[1]) ||
            appConfig.runtimeConfig.values
    }

    componentWillMount = async() => {
        if(this.state.values.i1101 || this.state.values.i1102) {
            let brandsList = this.props.clientConfig.brandIdList
                ? this.props.clientConfig.brandIdList
                : this.props.clientConfig.searchFilterStartup
                    ? this.props.clientConfig.searchFilterStartup.i1101
                        ? [this.props.clientConfig.searchFilterStartup.i1101]
                        : []
                    : [];

            let vars = {
                domain: {},
                expressions: [],
            }
            if(brandsList.length === 0){
                await graphqlClient.fetch(getFilters, vars).then(res => {
                    this.brandsList = res.filters.find(filter => filter.id === "1101").values;
                    const selectedBrandName = queryToJSON(document.location.hash.split("?")[1]).i1101;
                    const selectedBrand = this.brandsList.find(brand => brand.name === selectedBrandName);
                    brandsList = selectedBrand ? [selectedBrand.id] : [];
                })
            }
            vars.expressions = [`1101=${brandsList.join(",")}`];
            await graphqlClient.fetch(getFilters, vars).then(res => {
                this.brandsList = res.filters.find(filter => filter.id === "1101").values;
                this.modelList = res.filters.find(filter => filter.id === "1102").values;
            })
        }

        this.updateData(undefined, undefined, undefined, true).then(() => {
            Object.keys(this.state.values).map(
                (key) => {
                    if (appConfig.filterItems[key]) {
                        let filterValue = this.state.values[key];
                        if(key === 'condition'){
                            filterValue = filterValue === "1" ? "ALL" : filterValue === "0" ? "NEW" : filterValue;
                        }
                        $("#" + key).val(filterValue);
                    }
                    if (appConfig.checkItems[key]) {
                        $("#" + key).attr("checked", this.state.values[key]);
                    }
                    if (appConfig.inputItems[key]) {
                        $("#" + key)
                            .attr("value", this.state.values[key])
                            .val(
                                digitBeautify(this.state.values[key]) +
                                " " +
                                appConfig.inputItems[key].unit
                            );
                    }
                    if (appConfig.dateItems[key]) {
                        $("#" + key)
                            .attr("value", this.state.values[key])
                            .val(this.state.values[key]);
                    }
                }
            );
            if(this.state.filters["i1102"].values.length === 1){
                this.updateData(false, "i1102", this.state.filters["i1102"].values[0].name, true)
            }
            $.jStyling.updateSelect($("select"));
            $.jStyling.updateCheckbox($("input[type=checkbox]"));
        });
    };

    componentDidMount = () => {
        $.jStyling.createSelect($("select"));
        $.jStyling.createCheckbox($("input[type=checkbox]"));
    };

    handleReorder = () => {
        appConfig.runtimeConfig.page = 1;
        appConfig.runtimeConfig.carNotFound = null;
        this.setState({status: "loading", loading: true});
        this.updateData();
    };

    handleRepage = () => {
        appConfig.runtimeConfig.carNotFound = null;
        this.setState({status: "loading", loading: true, scroll: true});
        this.updateData();
    };

    partialResetFiltersState = (id) => {
        $("input#" + id)
            .val("0 руб.")
            .attr("value", "default");
        delete this.state.values[id];
        this.updateData();
    };

    resetFiltersStates = () => {
        this.state.values = {};
        appConfig.runtimeConfig = {
            page: 1,
            orderfield: this.props.clientConfig.searchOrderField
                ? this.props.clientConfig.searchOrderField
                : "price",
            ordermode: "asc",
            values: {},
            carNotFound: null
        };
        this.state.filters = this.state.initFilters;
        this.setState({status: "loading", extraSearch: 0, loading: true});
        this.updateData().then(() => {
            $("select").map(function () {
                $(this).val(
                    $(this)
                        .children("option:first")
                        .val()
                );
            });
            $(".jstyling-checkbox.active input[type=checkbox]").trigger("click");
            $("input[type=text]").map(function () {
                $(this)
                    .val("")
                    .attr("value", "");
            });
            $.jStyling.updateSelect($("select"));
            $.jStyling.updateCheckbox($("input[type=checkbox]"));
        });
    };

    toggleExtraSearch = () => {
        this.setState({extraSearch: +!this.state.extraSearch});
    };

    getHiddenFilters = () => {
        return Object.keys(this.props.clientConfig.searchFilterHiddenNew || {}).reduce(
            (res, key) => {
                if(!this.state.values[key]) {
                    res[key] =
                        (this.state.values[key] ? this.state.values[key] : "") === ""
                            ? this.props.clientConfig.searchFilterHiddenNew[key]
                            : this.state.values[key];
                }
                return res;
            },
            {}
        );
    };

    updateData = (original, id, value, withoutUpdateUrl) => {
        return new Promise((resolve) => {
            this.setState({status: "loading", loading: true});

            if (!original && value && value !== "default") {
                this.setState({status: "loading", loading: true});
                this.state.values[id] = value;
            } else {
                delete this.state.values[id];
            }

            appConfig.runtimeConfig.values = this.state.values;
            let h = $.param(this.state.values);

            if (!withoutUpdateUrl && this.props.location.search.substring(1) !== h) {
                rpc.navigate(h ? "#/search?" + h : "#", false);
                this.props.history.replace(h ? "/search?" + h : "");
            }

            const filterHidden = this.getHiddenFilters();

            let expressions = [];
            if(this.props.clientConfig.brandIdList && this.props.clientConfig.brandIdList.length === 1
                && !(this.props.clientConfig.searchButtonSetNew && this.props.clientConfig.searchButtonSetNew.i1101)) {
                expressions.push(`1101=${this.props.clientConfig.brandIdList.join(",")}`);
            }
            let domainFilter = {
                filters: this.props.clientConfig.dealersIdList[0] === "root" ? [] : [{
                    key: "1002",
                    values: this.props.clientConfig.dealersIdList
                }]
            };
            let domain = {
                channel: "DEALER",
                ...domainFilter
            };
            for (let value in filterHidden) {
                if(value !== "region") {
                    if (value !== "condition") {
                        if (value === "kdfrom") {
                            domain.filters.push({
                                key: getNewFilterName(value).replace("i", ""),
                                values: filterHidden[value]
                            })
                        } else {
                            domain.filters.push({
                                key: getNewFilterName(value).replace("i", ""),
                                values: filterHidden[value].split(',')
                            })
                        }
                    } else {
                        if (String(filterHidden[value]) === "0") {
                            domain.condition = "NEW"
                        } else {
                            domain.condition = "ALL"
                        }
                    }
                }
            }
            for (let filterName in this.state.values) {
                if(filterName !== "condition") {
                    if (this.state.values[filterName] !== "default" && filterName !== "region") {
                        let value = this.state.values[filterName];
                        if(filterName === "i1101"){
                            value = (this.brandsList.find(brand => brand.name === value) || {}).id;
                        }
                        if(filterName === "i1102"){
                            if(value.indexOf(":") !== -1){
                                let valuesList = value.split(":")
                                value = "";
                                valuesList.forEach(((val, index) => {
                                    value += `${(this.modelList.find(model => model.name === val) || {}).id}`
                                    if(index !== valuesList.length - 1){
                                        value += ","
                                    }
                                }))
                            }else {
                                value = (this.modelList.find(model => model.name === value) || {}).id;
                            }
                        }
                        expressions.push(`${getNewFilterName(filterName).replace("i", "")}=${value}`)
                    }
                }else {
                    const filterValue = String(this.state.values[filterName]);

                    if(filterValue === "0" || filterValue === "NEW"){
                        domain.condition = "NEW"
                    }
                    if(filterValue === "1" || filterValue === "ALL"){
                        domain.condition = "ALL"
                    }
                    if(filterValue === "USED"){
                        domain.condition = "USED"
                    }
                }
            }
            const carsPerPage = this.props.clientConfig.searchPerPageCount || appConfig.pagerConfig.perpage;
            let sort;
            let orderList = appConfig.runtimeConfig.orderfield.split(",").map(item => item.replace("-", ""))    ;
            sort = orderList.map(order => {
                let sort;
                switch (order) {
                    case "i1101":
                        sort = "TITLE"
                        break;
                    case "pp":
                        sort = "TRANSMISSION"
                        break;
                    case "engine":
                        sort = "POWER"
                        break;
                    case "special":
                        sort = "SPECIAL"
                        break;
                    case "price":
                    default:
                        sort = "PRICE"
                        break;
                }
                if(sort !== "SPECIAL") {
                    if (appConfig.runtimeConfig.ordermode === "asc") {
                        sort += "_ASC";
                    } else {
                        sort += "_DESC";
                    }
                }
                return sort;
            });

            const vars = {
                first: carsPerPage,
                offset: (appConfig.runtimeConfig.page - 1) * carsPerPage,
                expressions: expressions,
                domain: domain,
                sort: sort,
            }
            const hasRegionFilter = this.props.clientConfig.searchFilterSet.some(item => item === "region");
            const loadHiddenRegions = this.props.clientConfig.searchFilterHiddenNew && this.props.clientConfig.searchFilterHiddenNew.region && !(this.state.values && this.state.values.region)
            if(hasRegionFilter){
                if(loadHiddenRegions){
                    vars.ids = this.props.clientConfig.searchFilterHiddenNew.region.split(", ")
                } else if (this.state.values && this.state.values.region) {
                    vars.geo = {
                        ids: [this.state.values.region],
                        radius: 0
                    }
                }
            }

            graphqlClient.fetch(hasRegionFilter ? !loadHiddenRegions ? getCarsListWithAllRegions : getCarsListWithSelectedRegions : getCarsList, vars)
                .then(res => {
                    const newFilters = {};
                    if (hasRegionFilter) {
                        newFilters.region = {
                            id: "region",
                            name: "Регион",
                            values: !loadHiddenRegions
                                ? res.regions.edges.map(reg => {return {count: 1, ...reg.node}})
                                : res.regions.map(reg => {return {count: 1, ...reg}})
                        };
                    }
                    res.filters.forEach(filter => {
                        const filterId = getOldFilterName(filter.id);
                        newFilters[filterId] = filter
                    })
                    this.brandsList = res.filters.find(filter => filter.id === "1101").values;
                    this.modelList = res.filters.find(filter => filter.id === "1102").values;
                    this.versionsList = res.filters.find(filter => filter.id === "1103").values;

                    if(this.modelList.length === 1 && this.versionsList.length === 0 && !isRepeatedUpdateData){
                        isRepeatedUpdateData = true
                        this.updateData(false, "i1102", this.modelList[0].name, true).then(() => {
                            isRepeatedUpdateData = false
                        })
                    }

                    this.setState({
                        cars: res.offers.edges,
                        filters: newFilters,
                        initFilters: this.state.initFilters ? this.state.initFilters : newFilters,
                        count: res.offers.totalCount,
                        status: res.offers.totalCount === 0 ? "nocars" : "complete",
                        loading: false
                    }, () => {
                        resolve();
                        $.jStyling.updateSelect($("select"));
                        $.jStyling.updateCheckbox($("input[type=checkbox]"));
                    });
                });
        });
    };

    componentDidUpdate = () => {
        if (this.state.scroll && !this.state.loading) {
            this.setState({scroll: false});
        }
    };

    getSearchFilterFixed = (key) => {
        const {clientConfig} = this.props;
        return (clientConfig.searchFilterFixed &&
            clientConfig.searchFilterFixed[key]) && this.state.filters[key]
            ? clientConfig.searchFilterFixed[key].map((item, index) => {
                let result;
                if(this.state.filters[key].values){
                    result = this.state.filters[key].values.find(filter => filter.name === item);
                    if(!result){
                        result = {
                            id: item,
                            name: item
                        }
                    }
                }else{
                   result = {
                       name: clientConfig.searchFilterFixed[key][index],
                       id: key === "i6104"
                           ? `${clientConfig.searchFilterFixed[key][index].replace(/([\[\]])/g, "").replace(",", "..").replace("null", "")}`
                           : clientConfig.searchFilterFixed[key][index]
                   }
                }
                return result;
            }).filter(item => !!item)
            : null;
    };

    getOptionsForFilters = (key, searchFilterFixed) => {
        if(key === 'condition' && Object.keys(this.state.filters).length > 0){
            return [
                {
                    checked: false,
                    id: "NEW",
                    name: "Новые"
                },
                {
                    checked: false,
                    id: "ALL",
                    name: "Все"
                },
                {
                    checked: false,
                    id: "USED",
                    name: "С пробегом"
                },
            ]
        }
        if((this.state.filters[key] || []).length === 0){
            return [[appConfig.filterItems[key].nulled]]
        }
        let filteredValues = key === "i1002" || key === "i1101" || key === "i1102" || !this.state.filters[key].values
            ? this.state.filters[key].values || []
            : this.state.filters[key].values.filter(val => val.count > 0 || this.state.values[key] === val.id);
        if((this.state.filters[key].values || []).length === 1){
            return this.state.filters[key].values
        }else{
            return $.merge(
                [{id: "default", name: appConfig.filterItems[key].nulled}],
                searchFilterFixed
                    ? searchFilterFixed
                    : filteredValues || []
            )
        }
    };

    getDisabledForFilters = (key) => {
        return !!((appConfig.filterItems[key].disabled &&
            (this.state.filters[key] || []).length <= 1) ||
            (this.state.filters[key] && this.state.filters[key].values && this.state.filters[key].values.length <= 1));
    };

    render() {
        const {clientConfig} = this.props;

        let values = clientConfig.searchNoCars
            ? replaceSubstringByValue(
                clientConfig.searchNoCars,
                $.param(this.state.values)
            )
            : null;

        const buttons = Object.keys(clientConfig.searchButtonSetNew || []).map((key) => {
            return clientConfig.searchButtonSetNew[key].map((value) => {
                    return (
                        <ButtonItem
                            clientConfig={clientConfig}
                            updateFilterItem={this.updateData}
                            updateResultBlock={this.updateData}
                            id={value.name}
                            key={value.name}
                            value={value.name}
                            name={value.name}
                            parent={key}
                            values={this.state.values[key]}
                        />
                    )
                }
            );
        });

        let searchFilterFixed = {}
        const filters = (
            clientConfig.searchFilterSet ||
            Object.keys(appConfig.filterItems).slice(0, 8)
        ).map((key) => {
            searchFilterFixed[key] = this.getSearchFilterFixed(key);
            let options = this.getOptionsForFilters(key, searchFilterFixed[key]);
            let disabled = this.getDisabledForFilters(key);

            return (
                    <FilterItem
                        clientConfig={clientConfig}
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        options={options}
                        disabled={disabled}
                        key={key}
                        id={key}
                    />
                );
            }
        );

        const filtersExtra = (
            clientConfig.searchFilterSetExtra || []
        ).map(
            function (key) {
                searchFilterFixed[key] = this.getSearchFilterFixed(key);
                let options = this.getOptionsForFilters(key, searchFilterFixed[key]);
                let disabled = this.getDisabledForFilters(key);
                return (
                    <FilterItem
                        clientConfig={clientConfig}
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        options={options}
                        disabled={disabled}
                        key={key}
                        id={key}
                    />
                );
            }.bind(this)
        );

        const checks = (clientConfig.searchCheckSet || []).map(
            (key) => {
                return (
                    <CheckItem
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        key={key}
                        id={key}
                        clientConfig={clientConfig}
                    />
                );
            }
        );

        const checksExtra = (clientConfig.searchCheckSetExtra || []).map(
            (key) => {
                return (
                    <CheckItem
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        key={key}
                        id={key}
                        clientConfig={clientConfig}
                    />
                );
            }
        );

        const inputs = (clientConfig.searchInputSet || []).map(
            (key) => {
                return (
                    <InputItem
                        values={this.state.values}
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        partialResetFiltersState={this.partialResetFiltersState}
                        key={key}
                        id={key}
                        clientConfig={clientConfig}
                    />
                );
            }
        );

        const dates = (clientConfig.searchDateSet || []).map(
            (key) => {
                return (
                    <DateItem
                        values={this.state.values}
                        updateFilterItem={this.updateData}
                        updateResultBlock={this.updateData}
                        partialResetFiltersState={this.partialResetFiltersState}
                        key={key}
                        id={key}
                        clientConfig={clientConfig}
                    />
                );
            }
        );

        return (
            <div id="search">
                {appConfig.runtimeConfig.carNotFound &&
                (this.state.status === "loading" || this.state.status === "complete") && (
                    <div className="search-page__lost-car-message">
                        {appConfig.systemMessages.lostcar}
                    </div>
                )}
                <div className={"search-page__filters-block"}>
                    {clientConfig.searchButtonSetNew && (
                        <div className="filters-block__buttons-container">{buttons}</div>
                    )}
                    <div className="filters-block__filters-container">{filters}</div>
                    <div className="filters-block__inputs-container">{inputs}</div>
                    <div className="filters-block__dates-container">{dates}</div>
                    {clientConfig.searchCheckSet && (
                        <div className="filters-block__checks-container">{checks}</div>
                    )}
                    <div className="filters-block__info-tools">
                        <span>
                          {this.state.status !== "nocars"
                              ? appConfig.systemMessages[this.state.status]
                              : clientConfig.searchNoCars
                                  ? false
                                  : appConfig.systemMessages[this.state.status]}
                            {this.state.status === "complete" && (
                                <span>
                              : &nbsp;{" "}
                                    <b>
                                {this.state.count
                                    .toString()
                                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
                              </b>
                            </span>
                            )}
                        </span>
                        <span onClick={this.resetFiltersStates}>
                          {appConfig.systemMessages.reset}
                        </span>
                        {(clientConfig.searchFilterSetExtra ||
                        clientConfig.searchCheckSetExtra) && (
                            <span onClick={this.toggleExtraSearch}>
                                {appConfig.systemMessages.extraSearch}
                            </span>
                        )}
                    </div>
                    <div
                        className={
                            "filters-block__extra-filters-container" +
                            (this.state.extraSearch === 1 ? " visible" : "")
                        }
                    >
                        {filtersExtra}
                    </div>
                    <div
                        className={
                            "filters-block__extra-checks-container" +
                            (this.state.extraSearch === 1 ? " visible" : "")
                        }
                    >
                        {checksExtra}
                    </div>
                </div>
                {!this.state.loading && this.state.scroll && <ScrollToTopOnMount/>}
                {this.state.count > 0 ? (
                    <div className="results-block__results-container">
                        <TitlesBlock
                            handleReorder={this.handleReorder}
                            status={this.state.status}
                            clientConfig={clientConfig}
                        />
                        <ResultsBlock
                            cars={this.state.cars}
                            count={this.state.count}
                            status={this.state.status}
                            clientConfig={clientConfig}
                        />
                        <PagerBlock
                            handleRepage={this.handleRepage}
                            count={this.state.count}
                            status={this.state.status}
                            clientConfig={clientConfig}
                        />
                    </div>
                ) : this.state.status === "nocars" &&
                    clientConfig.searchNoCars && (
                        <div
                            className="search-page__nocars-block"
                            key="nocars"
                            dangerouslySetInnerHTML={{__html: values}}
                        />
                    )}
                {!this.state.loading && <AutoHeight/>}
            </div>
        );
    }
}

export default withRouter(FiltersBlock);
