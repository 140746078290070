import React from "react";
import createReactClass from "create-react-class";
import {
    buildCardPad,
    sortObject
} from "../../../utils/Global";
import {
  appConfig,
} from "../../../utils/constants";
import AutoHeight from "../../shared/AutoHeight";

class CardPad extends React.Component{
  state = {
    opened:
        !(this.props.clientConfig &&
            this.props.clientConfig.cardPadItemDefaultClosed)
  };

  toggleCardPadVisibility = () => {
    this.setState({
      opened: !this.state.opened
    });
  };

  render() {
    const pad = buildCardPad(this.props.data, this.props.type, this.props.description, this.props.isUsed);

    pad.base = this.props.additional ? {} : sortObject(pad.base);
    pad.additional = sortObject(pad.additional);

    return (Object.keys(pad.base).length > 0 ||
      Object.keys(pad.additional).length > 0) && (
      <div>
        <div
          className={
            "card-content-pad__car-pad-title" +
            (this.state.opened ? " opened" : " closed")
          }
          key={this.props.name}
          onClick={this.toggleCardPadVisibility}
        >
          {appConfig.cardConfig.groups[this.props.name]}
        </div>
        <div className="card-content-bar__card-content-pad">
          {Object.values(pad.base).concat(Object.values(pad.additional))}
        </div>
        <AutoHeight />
      </div>
    );
  }
}

export default CardPad;
