import React from "react";
import { digitBeautify } from "../../utils/Global.js";

class PriceBlock extends React.Component {
  render() {
    const { car, clientConfig } = this.props;
      let discountString = clientConfig.searchRequestText || (clientConfig.searchRequestDescription && car.description ? car.description : "по запросу");
      let discountPrice = null;
      if(clientConfig.searchPriceType) {
          if (clientConfig.searchPriceType === 1) {
              discountPrice = digitBeautify(car.retailPrice);
          }
          if (clientConfig.searchPriceType === 2) {
              discountPrice = digitBeautify(car.maxPrice);
          }
          if (clientConfig.searchPriceType === 3) {
              discountPrice = digitBeautify(car.minPrice);
          }
      } else if(car.retailPriceVisible){
          discountPrice = digitBeautify(car.retailPrice);
      }

      let basePrice = null
      if(clientConfig.searchPriceType && clientConfig.searchPriceType === 1 && car.basePrice > car.retailPrice > 0){
          basePrice = digitBeautify(car.basePrice);
      }
      if(car.basePriceVisible && car.basePrice > 0 && car.basePrice >= car.retailPrice && (car.basePrice !== car.retailPrice || !car.retailPriceVisible)){
          basePrice = digitBeautify(car.basePrice);
      }

      let marketingPrice = null
      if((clientConfig.searchMarketingPriceVisible || clientConfig.pricesWithDiscounts) && car.marketingPrice > 0){
          marketingPrice = digitBeautify(car.marketingPrice);
      }

      return (
      <div className="price-block">
        {marketingPrice && (
            <div>
                <span>
                    {marketingPrice}
                    <span className="rouble to-result-cell" />
                </span>
            </div>
        )}
        {(!marketingPrice || marketingPrice !== discountPrice) &&
          <div className={marketingPrice ? 'sale' : ''}>
            <span>
                {discountPrice || discountString}
                <span className={discountPrice ? 'rouble to-result-cell' : 'by-request'}/>
            </span>
          </div>
        }
        {basePrice && !clientConfig.pricesWithDiscounts &&
            <div className={discountPrice ? 'sale' : ''}>
                <span>
                    {basePrice}
                    <span className="rouble to-result-cell"/>
                </span>
            </div>
        }
      </div>
    );
  }
}

export default PriceBlock;
