import { request } from 'graphql-request';

const graphqlServerPath = "https://www.ilsa.ru/graphql";

export default {
  fetch: (query, variables) => {
    return new Promise((resolve, reject) => {
      request(graphqlServerPath, query, variables)
          .then(result => resolve(result))
          .catch(error => reject())
    });
  },
};
