import React from "react";
import $ from "jquery";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

const CheckItem = createReactClass({
  handleClick: function(e) {
    $("#" + this.props.id).trigger("click", e);
  },
  componentDidMount: function() {
    $("#" + this.props.id).on(
      "click",
      function(e) {
        appConfig.runtimeConfig.page = 1;
        appConfig.runtimeConfig.carNotFound = null;
        this.props.updateFilterItem(
          false,
          e.target.id,
          this.props.clientConfig.searchCheckSetValue &&
            this.props.clientConfig.searchCheckSetValue[this.props.id]
            ? document.getElementById(e.target.id).checked ? "1" : "default"
              ? this.props.clientConfig.searchCheckSetValue[this.props.id]
              : document.getElementById(e.target.id).checked ? "1" : "default"
            : document.getElementById(e.target.id).checked ? "1" : "default",
          true
        );
        this.props.updateResultBlock();
      }.bind(this)
    );
  },
  render: function() {
    return (
      <div className="filters-block__check-item">
        <input type="checkbox" id={this.props.id} />
        <label htmlFor={this.props.id}>
          {this.props.clientConfig.searchCheckSetText &&
          this.props.clientConfig.searchCheckSetText[this.props.id]
            ? this.props.clientConfig.searchCheckSetText[this.props.id]
            : appConfig.checkItems[this.props.id].title}
        </label>
      </div>
    );
  }
});

export default CheckItem;
