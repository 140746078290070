export const systemLocations = {
    filtersLocation: "/-/auto/v2/cars/filters",
    carsLocation: "/-/auto/v2/cars",
    offersLocation: "/-/auto/v1/offers/{id}/claim",
    accessoriesLocation: "/-/ad/v1/creatives",
    staticLocation: "/static/",
    searchSaleLabel: "sale_std.png",
    serverLocation: "/init/"
};

export const appConfig = {
    systemMessages: {
        loading: "Получение данных...",
        error: "Ошибка доступа к приложению",
        reset: "Сбросить всё",
        complete: "Найдено автомобилей",
        nocars: "Не найдено автомобилей",
        specialoffer: "Горячее предложение дилера",
        specialofferdescr: "Комментарий дилера",
        extraequipment: "Установлено дополнительное оборудование",
        lostcar:
            "Выбранный и сохраненный Вами автомобиль снят с продажи. Пожалуйста, воспользуйтесь новым поиском автомобиля.",
        extraSearch: "Расширенный поиск",
        browserError:
            "К сожалению, этот браузер не поддерживает работу нашего виджета."
    },
    systemPages: {
        search: "SearchPage",
        card: "CardPage",
        offer: "OfferPage",
        claimcredit: "OfferPage",
        claimtradein: "OfferPage",
        message: "MessagePage"
    },
    filterItems: {
        region: { title: "Город", nulled: "Все" },
        i1101: { title: "Марка", nulled: "Все", children: "i1102", sorted: true },
        i1102: {
            title: "Модель",
            nulled: "Все",
            children: "i1103",
            disabled: true,
            sorted: true
        },
        i1103: {
            title: "Комплектация",
            nulled: "Все",
            disabled: true,
            sorted: true
        },
        availability: { title: "Статус", nulled: "Не важно" },
        i6201: { title: "Тип КПП", nulled: "Не важно" },
        i6101: { title: "Тип топлива", nulled: "Не важно" },
        i2101: { title: "Тип кузова", nulled: "Не важно" },
        dealer: { title: "Дилер", nulled: "Все", sorted: true },
        i2201: { title: "Цвет кузова", nulled: "Не важно", sorted: true },
        i3104: { title: "Цвет обивки сидений", nulled: "Не важно" },
        i3103: { title: "Обивка сидений", nulled: "Не важно" },
        i5413: { title: "Климат", nulled: "Не важно" },
        i6301: { title: "Привод", nulled: "Не важно" },
        i5201: { title: "Фары", nulled: "Не важно" },
        i2301: { title: "Диски", nulled: "Не важно" },
        i5418: { title: "Мультимедиа", nulled: "Не важно" },
        i6104: { title: "Объем двигателя", nulled: "Не важно" },
        i2302: { title: "Размер дисков", nulled: "Не важно", sorted: true },
        condition: { title: "Состояние" }
    },
    checkItems: {
        special: { title: "Горячее предложение" },
        campaign: { title: "Кампания" },
        i4102: { title: "ABS" },
        i4103: { title: "ESP" },
        i5505: { title: "Датчик дождя" },
        i5205: { title: "Датчик света" },
        i5407: { title: "Эл. привод стекол" },
        i5406: { title: "Эл. привод зеркал" },
        i5401: { title: "Обогрев зеркал" },
        i5402: { title: "Обогрев лоб. стекла" },
        i5412: { title: "Мультируль" },
        i5405: { title: "Обогрев руля" },
        i5404: { title: "Обогрев сидений" },
        i5408: { title: "Эл. привод сидений" },
        i5409: { title: "Память настроек" },
        i5503: { title: "Круиз-контроль" },
        i5419: { title: "Парктроник" },
        i5502: { title: "Навигация" },
        i4125: { title: "Камера" },
        i5416: { title: "Люк на крыше" },
        i5417: { title: "Панорамная крыша" },
        i5302: { title: "Пневмоподвеска" },
        i5421: { title: "Доп. отопитель" }
    },
    inputItems: {
        pricefrom: { id: "1201", title: "Цена от", unit: "руб.", isFirst: true },
        priceto: { id: "1201", title: "Цена до", unit: "руб." },
        mileageFrom: { id: "1034", title: "Пробег от", unit: "км.", isFirst: true },
        mileageTo: { id: "1034", title: "Пробег до", unit: "км." }
    },
    dateItems: {
        kdfrom: { title: "Дата от" },
        kdto: { title: "Дата до" }
    },
    resultTitles: {
        i1101: { title: "Автомобиль", internal: "i1101,i1102,i1103" },
        kpp: { title: "КПП", internal: "i6201" },
        engine: { title: "Двигатель", internal: "i6104,i6101,i6105" },
        price: { title: "Цена", internal: "price" }
    },
    headlineAttrs:
        "i5413,i5418,i5502,i3103,i4101,i4102,i4103,i5201,i5101,i5407,i5408,i5406,i5503,i5505,i5402,i5404,i5419,i5416,i5417,i5302,i2301,i2302,i5421",
    headlineTransition: {
        text: "item.text",
        choice:
            "value[item].choice.simple == false ? value[item].choice.abbr || value[item].choice.name : null",
        boolean:
            "value[item].has == true ? value[item].attr.abbr || value[item].attr.name : null",
        number:
            'value[item].attr.id == 4101 ? (value[item].value + " Airbag" + (value[item].value > 1 ? "s" : "")) : value[item].value'
    },
    locationTransition: {
        offer: "car",
        claimcredit: "car",
        claimtradein: "car",
        card: "search"
    },
    pagerConfig: { center: 3, visible: 5, perpage: 10 },
    cardConfig: {
        groups: {
            body: "Кузов",
            equip: "Функциональное оборудование",
            interior: "Салон",
            main: "Общие данные",
            safety: "Безопасность",
            tech: "Технические характеристики",
            warranty: "Гарантия",
            extended: "Дополнительная информация"
        },
        types: {
            text: "value.text",
            number: "value.value",
            choice: "value[type].name",
            boolean: "value.has ? 'есть' : 'нет'"
        },
        ignored: { short: [3201, 3301, 3401], full: [1107, 1101] },
        transitions: {
            types: {
                boolean: "has",
                choice: "choice.name",
                number: "value",
                text: "text"
            },
            items: {
                base: "value.base",
                classname:
                    "'card-content-pad__car-pad-item' +(value.base?'':' additional')",
                has: "value.has",
                type: "value.attr.type",
                id: "value.attr.id"
            }
        }
    },
    offerConfig: {
        offerKey:
            "Bearer Nzc0NjI2M2Q3N2YyNTc3NGJlMzQ2YWRmOWU4MTgyN2Y5MTZmOGMwMmJiYTdjMjMxODJjMzY5NWZlNDkzMGZlYg",
        offerUri: "http://yandex.ru"
    },
    runtimeConfig: { orderfield: null, ordermode: "asc", page: 1, values: {} },
    newFiltersName: {
        availability: "i1401",
        dealer: "i1002",
        kdfrom: "i9103",
        special: "i1052",
    }
};
