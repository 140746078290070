import React from "react";
import $ from "jquery";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

class FilterItem extends React.Component{
  componentDidMount() {
    $("#" + this.props.id).on(
      "change", (e) => {
        appConfig.runtimeConfig.page = 1;
        appConfig.runtimeConfig.carNotFound = null;
        this.props.updateFilterItem(false, e.target.id, e.target.value, true);
        if (
          (this.props.clientConfig.searchFilterSet &&
            this.props.clientConfig.searchFilterSet.filter(function(i) {
              return i == appConfig.filterItems[e.target.id].children;
            }).length == 1) ||
          (appConfig.filterItems[e.target.id].children ||
            (appConfig.filterItems[e.target.id].children &&
              appConfig.filterItems[appConfig.filterItems[e.target.id].children]
                .children) ||
            (this.props.clientConfig.searchFilterSet &&
              this.props.clientConfig.searchFilterSet[
                appConfig.filterItems[e.target.id].children
              ]))
        ) {
          $("#" + appConfig.filterItems[e.target.id].children).val(
            $(
              "#" +
                appConfig.filterItems[e.target.id].children +
                " option:first"
            ).val()
          );
          $(
            "#" +
              appConfig.filterItems[appConfig.filterItems[e.target.id].children]
                .children
          ).val(0);
          this.props.updateFilterItem(
            false,
            appConfig.filterItems[e.target.id].children,
            $("#" + appConfig.filterItems[e.target.id].children).val(),
            false
          );
          this.props.updateFilterItem(
            false,
            appConfig.filterItems[appConfig.filterItems[e.target.id].children]
              .children,
            0,
            false
          );
        } else {
          this.props.updateResultBlock();
        }
      }
    );
  };

  render() {
    const isBrandOrModel = this.props.id === "i1101" || this.props.id === "i1102";
    const options = this.props.options.map((item) => {
        return (
          <option
            key={item ? item.id : "default"}
            value={
              $.inArray(item, ["Все", "Не важно"]) > -1 ||
              this.props.options.length === 1
                ? "default"
                : isBrandOrModel && item.id !== "default" ? item.name : item.id
            }
          >
            {this.props.clientConfig.searchFilterTranslator &&
            this.props.clientConfig.searchFilterTranslator[this.props.id] &&
            this.props.clientConfig.searchFilterTranslator[this.props.id][
              item.name
            ]
              ? this.props.clientConfig.searchFilterTranslator[this.props.id][
                  item.name
                ]
              : item.name}
          </option>
        );
      });
    return (
      <div className="filters-block__filter-item">
        <div>
          {this.props.clientConfig.searchFilterSetText &&
          this.props.clientConfig.searchFilterSetText[this.props.id]
            ? this.props.clientConfig.searchFilterSetText[this.props.id]
            : appConfig.filterItems[this.props.id].title}
        </div>
        <select disabled={this.props.disabled} id={this.props.id}>
          {options}
        </select>
      </div>
    );
  }
};

export default FilterItem;
