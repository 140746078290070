import React from "react";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

const TitlesBlock = createReactClass({
  handleReorder: function(orderfield, ordermode, selectedfield) {
    appConfig.runtimeConfig.orderfield = orderfield;
    appConfig.runtimeConfig.ordermode =
      orderfield == selectedfield
        ? ordermode == "asc"
          ? "desc"
          : "asc"
        : "asc";
    this.props.handleReorder();
  },

  renderHeaders() {
    const { clientConfig } = this.props;

    return ["i1101", "kpp", "engine", "price"].map(name => {
      const title =
        (clientConfig.searchTitlesRename &&
          clientConfig.searchTitlesRename[name]) ||
        appConfig.resultTitles[name].title;
      return (
        <div
          key={name}
          onClick={this.handleReorder.bind(
            this,
            name,
            name === appConfig.runtimeConfig.orderfield.split(",")[0]
              ? appConfig.runtimeConfig.ordermode
              : "asc",
            appConfig.runtimeConfig.orderfield
          )}
          className={
            "results-title-wrapper__title-item " +
            name +
            (name === appConfig.runtimeConfig.orderfield.split(",")[0]
              ? " selected"
              : "")
          }
        >
          <span>{title}</span>
          <span
            className={
              "sorted-by " +
              (name === appConfig.runtimeConfig.orderfield
                ? appConfig.runtimeConfig.ordermode
                : "")
            }
          />
        </div>
      );
    });
  },

  render: function() {
    if (this.props.status !== "complete") {
      return false;
    }

    return (
      <div className="results-container__results-title-wrapper">
        {this.renderHeaders()}
      </div>
    );
  }
});

export default TitlesBlock;
