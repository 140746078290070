import React from "react";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";

const PagerBlock = createReactClass({
  handleRepage: function(page, total) {
    if (page > 0 && page <= total && page != appConfig.runtimeConfig.page) {
      appConfig.runtimeConfig.page = page;
      this.props.handleRepage();
    }
  },
  render: function() {
    var center = appConfig.pagerConfig.center,
      visible = appConfig.pagerConfig.visible,
      perpage =
        this.props.clientConfig.searchPerPageCount ||
        appConfig.pagerConfig.perpage;
    const total =
      Math.floor(this.props.count / perpage) == this.props.count / perpage
        ? Math.floor(this.props.count / perpage)
        : Math.floor(this.props.count / perpage) + 1;
    var first =
        total < visible
          ? 1
          : appConfig.runtimeConfig.page <= center
          ? 1
          : appConfig.runtimeConfig.page <= total - center
          ? appConfig.runtimeConfig.page - center + 1
          : total - visible + 1,
      last = first + Math.min(total, visible) - 1,
      pages = [];
    for (var i = first; i <= last; i++) {
      pages.push(
        <a
          href="#search"
          className={
            "pages-container__page-item " +
            (appConfig.runtimeConfig.page == i ? "selected" : "")
          }
          key={i}
          onClick={this.handleRepage.bind(this, i, total)}
        >
          {i}
        </a>
      );
    }
    return total > 1 && this.props.status == "complete" ? (
      <div className="results-block__pages-container">
        <a
          className="pages-container__simple-link prev"
          onClick={this.handleRepage.bind(
            this,
            appConfig.runtimeConfig.page - 1,
            total
          )}
        >
          {this.props.clientConfig.searchPrevText
            ? this.props.clientConfig.searchPrevText
            : "Предыдущая"}
        </a>
        {pages}
        <a
          className="pages-container__simple-link next"
          onClick={this.handleRepage.bind(
            this,
            appConfig.runtimeConfig.page + 1,
            total
          )}
        >
          {this.props.clientConfig.searchNextText
            ? this.props.clientConfig.searchNextText
            : "Следующая"}
        </a>
      </div>
    ) : (
      false
    );
  }
});

export default PagerBlock;
