import React from "react";
import { sortObject } from "../../../utils/Global";
import $ from "jquery";

class CardList extends React.Component {
  render() {
    const list = [];

    $.each(sortObject(this.props.data.attrs.tech), function(key, value) {
      list.push(
        <div className="card-content-item__card-content-line" key={key}>
          <div>{value.name}: </div>
          <div>{value.has === null
              ? "нет данных"
              : `${value.ins && value.ins.length > 0 
                  ? value.ins[0].description 
                  : value.value 
                      ? value.value
                      : value.has
                        ? "есть"
                        : "нет"
          } ${value.unit ? value.unit : ""}`}</div>
        </div>
      );
    });
    return <div>{list}</div>;
  }
}

export default CardList;
