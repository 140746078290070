import React from "react";
import $ from "jquery";
import createReactClass from "create-react-class";
import { appConfig } from "../../../utils/constants";
import {digitBeautify} from "../../../utils/Global";

class InputItem extends React.Component {
    unit = null;
    timeout = null;

  handleKeyup = (e) => {
    const valueFromProps = this.props.values[appConfig.inputItems[this.props.id].id];
    const isFirst = appConfig.inputItems[this.props.id].isFirst;
    const filterId = appConfig.inputItems[this.props.id].id;

    e.persist();
    $("#" + e.target.id)
      .val(digitBeautify(e.target.value.replace(/\s/g, "")))
      .attr("value", e.target.value);

    if (this.timeout) {
        clearTimeout(this.timeout);
    }

    let value = "";
    let valueWithoutLetters = `${!isFirst ? ".." : ""}${e.target.value
        .replace(/\s/g, "")
        .replace(this.unit, "")}${isFirst ? ".." : ""}`

    if(valueFromProps){
        value = isFirst
            ? `${valueWithoutLetters}${valueFromProps.slice(valueFromProps.lastIndexOf('.') + 1)}`
            : `${valueFromProps.slice(0, valueFromProps.indexOf('.'))}${valueWithoutLetters}`
    }else{
        value = valueWithoutLetters
    }
    if(value === ".."){
        value = "";
    }
    this.timeout = setTimeout(
      function () {
          this.props.updateFilterItem(
              false,
              filterId,
              value
          );
          this.props.updateResultBlock();
      }.bind(this),
      2000
    );
  }

  handleDigits = (e) => {
    return e.charCode >= 48 && e.charCode <= 57;
  }

  partialResetFiltersState = (id) => {
    this.props.partialResetFiltersState(id);
  }

  handleBlur = (e) => {
    $("#" + e.target.id).val(
      digitBeautify(e.target.value) +
        (e.target.value ? ` ${this.unit}` : "")
    );
  }

  handleFocus = (e) => {
    e.preventDefault();
    $("#" + e.target.id)
      .val(
        e.target.value
          .replace(/\s/g, "")
          .replace(this.unit, "")
      )
      .select();
  }

  componentDidMount = () => {
    const value = this.props.values[appConfig.inputItems[this.props.id].id];
    const isFirst = appConfig.inputItems[this.props.id].isFirst;

    this.unit = appConfig.inputItems[this.props.id].unit;

    if (!value) {
        $("#" + this.props.id).val(`0 ${this.unit}`);
    }else{
        if(isFirst){
            $("#" + this.props.id).val(value.slice(0, value.indexOf('.')));
        }else{
            $("#" + this.props.id).val(value.slice(value.lastIndexOf('.') + 1));
        }
    }
  }

  render() {
    return (
      <div className="filters-block__input-item">
        <label htmlFor={this.props.id}>
          {this.props.clientConfig.searchInputSetText &&
          this.props.clientConfig.searchInputSetText[this.props.id]
            ? this.props.clientConfig.searchInputSetText[this.props.id]
            : appConfig.inputItems[this.props.id].title}
        </label>
        <input
          type="text"
          id={this.props.id}
          onKeyUp={this.handleKeyup}
          onKeyPress={this.handleDigits}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          defaultValue=""
          maxLength="10"
        />
        <div
          onClick={this.partialResetFiltersState.bind(this, this.props.id)}
        />
      </div>
    );
  }
}

export default InputItem;
