import React from "react";
import ReactDOM from "react-dom";
import { Link as BaseLink } from "react-router-dom";
import { InfoContext } from "../../context/ConfigContext";

class Link extends React.Component {
  static contextType = InfoContext;

  constructor(props) {
    super(props);

    this.handleInteract = this.handleInteract.bind(this);
  }
  handleInteract = e => {
    if (this.inited) {
      return;
    }

    const el = ReactDOM.findDOMNode(this);
    el.href = this.context.referrer + el.hash;
    this.inited = true;
  };

  render() {
    let to = {};
    if (typeof this.props.to === "string") {
      to = {
        pathname: this.props.to,
        state: { app: true }
      };
    } else {
      to = Object.assign(this.props.to, {
        state: Object.assign(this.props.to.state || {}, { app: true })
      });
    }

    return (
      <BaseLink
        {...this.props}
        to={to}
        onMouseOver={this.handleInteract}
        onTouchStart={this.handleInteract}
      />
    );
  }
}
export default Link;
