import React from "react";
import EasyXDM from "easyxdm";
import $ from "jquery";
import {appConfig} from "./constants";

export let rpc = null;

function isIframe() {
    try {
        let isSocialWidget = window.location.search.indexOf("https://facebook.com") !== -1 || window.location.search.indexOf("https://vk.com") !== -1;
        return window.self !== window.top && !isSocialWidget;
    } catch (e) {
        return true;
    }
}

export const initFrame = () => {
    if (isIframe()) {
        rpc = new EasyXDM.Rpc(
            {},
            {
                remote: {pos: {}, invoke: {}, navigate: {}, resize: {}}
            }
        )
    } else {
        rpc = {
            pos: () => {
            }, invoke: () => {
            }, navigate: () => {
            }, resize: () => {
            }
        }
    }
    window.rpc = rpc;
}
export const getPairedValues = (values, i, q) => {
    if (values && values[i]) {
        let paired = JSON.parse(values[i]);
        q = q.replace(
            new RegExp(`${i}%3A%5B${paired[0]}%2C${paired[1]}%5D`, "g"),
            `${paired[0] ? `${i}:>=${paired[0]}` : ""}${paired[0] && paired[1] ? "," : ""
            }${paired[1] ? `${i}:<=${paired[1]}` : ""}`
        );
    }
    return q;
};
export const queryToJSON = (q) => {
    let j = {};
    if (q)
        q.split("&").forEach(k => {
            j[k.split("=")[0]] = decodeURIComponent(
                k
                    .split("=")[1]
                    .replace(/\+/g, " ")
                    .replace(/%2B/g, "+")
            );
        });
    return Object.keys(j).length > 0 ? j : false;
};

export const dateBeautify = (x) => {
    let dt = x.split(/[: T-]/).map(parseFloat);
    return new Date(
        dt[0],
        dt[1] - 1,
        dt[2],
        dt[3] || 0,
        dt[4] || 0,
        dt[5] || 0,
        0
    ).toLocaleDateString("ru-RU");
};

export const buildCardPad = (data, propsType, propsDescription, isUsed) => {
    let pad = {base: {}, additional: {}};
    let src = sortObject(data);
    for (let i in src) {
        if (Object.keys(src[i]).length > 0) {
            let pushed = false;
            if (propsType === "full") {
                if (src[i].isFlag) {
                    continue;
                }
                for (let j in src[i].ins) {
                    let value = src[i].ins[j]
                    let itemKey = value.description;
                    // pad[value.base ? "base" : "additional"][`${i === "extra" ? "z-" : ""}${i}-${j}`] = (
                    pad[value.base ? "base" : "additional"][`${i === "extra" ? "z-" : ""}${"" + i + j}`] = (
                        <div
                            className={`card-content-pad__car-pad-item ${(!value.base && !isUsed) ? 'additional' : ''}`}
                            key={"card-full-" + i + "-" + j}
                        >
                            {itemKey}
                        </div>
                    )
                    pushed = true;
                }
            }
            if (!pushed) {
                let value = src[i];
                if (value.name) {
                    let itemInfo = `${value.name}${value.value !== null ? `: ${value.value}` : ""}`;
                    if (i !== "extra") {
                        pad[value.base ? "base" : "additional"]["" + i + "0"] = (
                            <div
                                className={`card-content-pad__car-pad-item ${(!value.base && !isUsed) ? 'additional' : ''}${(i === "e6" && propsDescription ? " description" : "")}`}
                                key={"card-full-" + i}
                            >
                                {itemInfo}
                            </div>
                        );
                    }
                }
            }
        }
    }
    return pad;
}

export function digitBeautify(x = 0) {
    return x.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
}

export function searchTextReplace(data, rep) {
    return Object.keys(rep).map(function (key) {
        return data.replace(new RegExp(key, "g"), rep[key]);
    });
}

export function clearEmpty(x) {
    let a, o;
    if ($.isArray(x)) {
        a = $.grep(x, function (n) {
            return n;
        });
    } else {
        o = {};
        $.grep(Object.keys(x), function (n) {
            return x[n];
        }).forEach(k => {
            o[k] = x[k];
        });
    }
    return $.isArray(x) ? a : o;
}

export function replaceSubstringByValue(x, car) {
    let matches = x.match(/[^{\]]+(?=})/g);
    if (matches)
        Object.keys(matches).forEach((string, index) => {
            x = x.replace("{" + matches[index] + "}", car[string]);
        });
    return x;
}

export function canView(allowed, actual) {
    if (allowed[0] === "root") {
        return true;
    }

    let expressionValue = actual.split("=")[1];
    return allowed.some(id => id === expressionValue);
}

export function sortObject(x) {
    let a = [],
        o = {};
    for (let i in x) {
        a.push(i);
    }
    a.sort();
    for (let i in a) {
        o[a[i]] = x[a[i]];
    }
    return o;
}

export function phoneValidate(x) {
    return x.replace(/[^0-9.]/g, "").length >= 10;
}

export function emailValidate(x) {
    return /^[a-zA-Z0-9!.$&*^`|~#%'+\/?_{}=-]+@[a-zA-Z]+\.[a-zA-Z]+$|^[а-яёА-ЯЁ0-9!.$&*^`|~#%'+\/?_{}=-]+@[а-яёА-ЯЁ]+\.[а-яёА-ЯЁ]+$/.test(x);
}

export function getObjectValueByPath(object, path, value) {
    path.split(".").map(item => (object = object[item]));
    return value.indexOf(object) !== -1;
}

export function setObjectValueByPath(object, path, value) {
    let i;
    path = path.split(".");
    for (i = 0; i < path.length - 1; i++) object = object[path[i]];
    object[path[i]] = value;
    return object;
}

export function getNewFilterName(filterName) {
    return appConfig.newFiltersName[filterName] ? appConfig.newFiltersName[filterName] : filterName;
}

export function getOldFilterName(filterName) {
    filterName = `i${filterName}`
    for (let i in appConfig.newFiltersName) {
        if (appConfig.newFiltersName[i] === filterName) {
            filterName = i
        }
    }
    return filterName;
}

export function isIOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function toDataURL(url, callback) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function() {
        let reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Accept', '*/*');
    xhr.send();
}
