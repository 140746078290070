import React from "react";

const context = React.createContext();
export default context;

export const InfoContext = React.createContext();

export function withConfig(Component) {
  return props => {
    return (
      <context.Consumer>
        {config => <Component {...props} clientConfig={config} />}
      </context.Consumer>
    );
  };
}